import React, { useState, useEffect, useContext } from 'react'
import { FlexGrid, Box, Feedback, Spacer } from '@telus-uds/components-web'

import { SiteBuilderContext } from '../../../renderer/context/SiteBuilderContext'

import StoryCard from './StoryCard'
import HeroContent from './HeroContent'
import Loader from '../Loader'
import { StoryCardContainer, TwoCardContainer } from './styles'
import helpers from './helpers'

export interface IStoryCardBlocKProps {
  storycard: {
    title: string
    description?: string
    url?: string
    articles?: string[]
    blogSlug: string
    blogCategory?: string
    numberOfArticles?: 1 | 2 | 3 | 4
    displayDate?: string
    textSize: string
    heading?: JSX.Element
    entryTitle: string
    cardHeight: number
    hideChevronLink?: boolean
  }
}

const StoryCardBlock = ({ storycard }: IStoryCardBlocKProps) => {
  const {
    title,
    description = '',
    url = '',
    articles,
    blogSlug,
    blogCategory,
    numberOfArticles = 4,
    displayDate,
    textSize,
    heading,
    cardHeight,
    hideChevronLink = false,
  } = storycard

  const { locale, currentPage } = useContext(SiteBuilderContext)
  const { getCategory, getArticles, appendIntcmp, getArticlesWithNoCat } = helpers
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [selectedArticles, setSelectedArticles] = useState([])

  useEffect(() => {
    setLoading(true)

    const fetchData = async () => {
      try {
        const category = await getCategory(locale.language, blogSlug, blogCategory)

        const featured = blogCategory
          ? await getArticles(locale.language, category, articles, numberOfArticles)
          : await getArticlesWithNoCat(locale.language, category, articles, numberOfArticles)
        if (featured) {
          const featuredArticlesSetter = featured.slice(0, numberOfArticles)

          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          setSelectedArticles(featuredArticlesSetter)
        }
      } catch (err) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setError(err)
      } finally {
        setLoading(false)
      }
    }

    void fetchData()
  }, [])

  const pathname = currentPage?.fields?.slug
  const domain = blogSlug === 'foundation' ? 'www.friendlyfuture.com' : 'www.telus.com'
  const verifiedUrl = url ? url : `https://${domain}/en/blog/${blogSlug}`

  const allUrl = appendIntcmp(pathname, blogSlug, verifiedUrl, blogSlug, blogCategory, locale.language, true)
  return (
    <div data-testid="story-card-block">
      {selectedArticles?.length === 0 && loading ? (
        <Loader />
      ) : error ? (
        <Feedback validation="error" variant={{ icon: true }} title="Sorry">
          Something went wrong...
        </Feedback>
      ) : (
        <FlexGrid>
          {selectedArticles?.length === 0 && (
            <>
              <FlexGrid.Row verticalAlign="middle">
                <FlexGrid.Col sx={12} lg={6}>
                  <Box vertical={{ lg: 4 }}>
                    <FlexGrid gutter={false}>
                      <FlexGrid.Row>
                        <FlexGrid.Col lg={8}>
                          <HeroContent
                            heading={heading}
                            textSize={textSize}
                            lang={locale.language}
                            text={title}
                            description={description}
                            url={allUrl}
                            hideChevronLink={hideChevronLink}
                          />
                        </FlexGrid.Col>
                      </FlexGrid.Row>
                    </FlexGrid>
                  </Box>
                </FlexGrid.Col>
              </FlexGrid.Row>
            </>
          )}
          {selectedArticles?.length === 1 && (
            <>
              <FlexGrid.Row verticalAlign="middle">
                <FlexGrid.Col sx={12} lg={6}>
                  <Box vertical={{ lg: 0 }}>
                    <FlexGrid.Row>
                      <FlexGrid.Col lg={11}>
                        <HeroContent
                          heading={heading}
                          textSize={textSize}
                          lang={locale.language}
                          text={title}
                          description={description}
                          url={allUrl}
                          hideChevronLink={hideChevronLink}
                        />
                      </FlexGrid.Col>
                    </FlexGrid.Row>
                  </Box>
                </FlexGrid.Col>

                {selectedArticles.map((item, index) => {
                  const field = item?.fields

                  return (
                    <FlexGrid.Col sm={12} lg={5} key={`${index}-story-card-${item.sys.id}`}>
                      <div style={{ paddingTop: '2rem' }}>
                        <StoryCard
                          displayDate={displayDate}
                          pathname={pathname}
                          language={locale.language}
                          blogCategory={blogCategory}
                          blogSlug={blogSlug}
                          field={field}
                        />
                      </div>
                    </FlexGrid.Col>
                  )
                })}
              </FlexGrid.Row>
            </>
          )}

          {selectedArticles?.length === 2 && (
            <>
              <FlexGrid.Row verticalAlign="middle">
                <FlexGrid.Col lg={4}>
                  <Box vertical={{ lg: 6 }} space={{ lg: 0, xs: 3 }}>
                    <FlexGrid gutter={false}>
                      <FlexGrid.Row>
                        <FlexGrid.Col lg={12}>
                          <HeroContent
                            heading={heading}
                            textSize={textSize}
                            lang={locale.language}
                            text={title}
                            description={description}
                            url={allUrl}
                            hideChevronLink={hideChevronLink}
                          />
                        </FlexGrid.Col>
                      </FlexGrid.Row>
                    </FlexGrid>
                  </Box>
                </FlexGrid.Col>

                <FlexGrid.Col lg={8}>
                  <TwoCardContainer cardHeight={cardHeight}>
                    <FlexGrid.Row>
                      {selectedArticles.map((item, index) => {
                        const field = item?.fields
                        return (
                          <FlexGrid.Col xs={12} md={6} key={`${index}-story-card-${item.sys.id}`}>
                            <Spacer space={4} />
                            <StoryCard
                              displayDate={displayDate}
                              pathname={pathname}
                              language={locale.language}
                              blogCategory={blogCategory}
                              blogSlug={blogSlug}
                              field={field}
                            />
                          </FlexGrid.Col>
                        )
                      })}
                    </FlexGrid.Row>
                  </TwoCardContainer>
                </FlexGrid.Col>
              </FlexGrid.Row>
            </>
          )}

          {selectedArticles?.length === 3 && (
            <>
              <FlexGrid.Col lg={12}>
                <Box vertical={{ lg: 4 }}>
                  <FlexGrid gutter={false}>
                    <FlexGrid.Row>
                      <FlexGrid.Col lg={12}>
                        <HeroContent
                          heading={heading}
                          textSize={textSize}
                          lang={locale.language}
                          text={title}
                          description={description}
                          url={allUrl}
                          hideChevronLink={hideChevronLink}
                        />
                      </FlexGrid.Col>
                    </FlexGrid.Row>
                  </FlexGrid>
                </Box>
              </FlexGrid.Col>
              <StoryCardContainer cardHeight={cardHeight}>
                {selectedArticles.map((item, index) => {
                  const field = item?.fields

                  return (
                    <Box key={`${index}-story-card-${item.sys.id}`} space={3}>
                      <StoryCard
                        displayDate={displayDate}
                        language={locale.language}
                        pathname={pathname}
                        blogCategory={blogCategory}
                        blogSlug={blogSlug}
                        field={field}
                      />
                    </Box>
                  )
                })}
              </StoryCardContainer>
            </>
          )}

          {selectedArticles?.length === 4 && (
            <>
              <FlexGrid.Col xs={12}>
                <Box vertical={{ lg: 4 }}>
                  <FlexGrid gutter={false}>
                    <FlexGrid.Row>
                      <FlexGrid.Col lg={12}>
                        <HeroContent
                          heading={heading}
                          textSize={textSize}
                          lang={locale.language}
                          text={title}
                          description={description}
                          url={allUrl}
                          hideChevronLink={hideChevronLink}
                        />
                      </FlexGrid.Col>
                    </FlexGrid.Row>
                  </FlexGrid>
                </Box>
              </FlexGrid.Col>
              <StoryCardContainer cardHeight={cardHeight}>
                {selectedArticles.map((item, index) => {
                  const field = item?.fields
                  return (
                    <Box key={`${index}-story-card`} space={3}>
                      <StoryCard
                        displayDate={displayDate}
                        pathname={pathname}
                        language={locale.language}
                        blogCategory={blogCategory}
                        blogSlug={blogSlug}
                        field={field}
                      />
                    </Box>
                  )
                })}
              </StoryCardContainer>
            </>
          )}
        </FlexGrid>
      )}
    </div>
  )
}

export default StoryCardBlock
