import { IStoryCardBlockFields } from 'contentful-types'

import { withContentful } from 'src/siteBuilder/renderer/contentful/withContentful'
import { BlockRenderer } from 'src/siteBuilder/renderer/contentful/BlockRenderer'

import StoryCardBlock, { IStoryCardBlocKProps } from './StoryCardBlock'

const ContentfulStoryCardBlock = withContentful<IStoryCardBlockFields>(StoryCardBlock, {
  storycard: (props): IStoryCardBlocKProps['storycard'] => {
    const {
      articles,
      blogCategory,
      blogSlug,
      description,
      entryTitle,
      numberOfArticles,
      title,
      url,
      displayDate,
      textSize,
      heading,
      cardHeight,
      renderLink,
    } = props.fields

    return {
      articles: articles,
      blogCategory: blogCategory,
      blogSlug: blogSlug,
      description: description,
      entryTitle: entryTitle,
      numberOfArticles: Number(numberOfArticles) as IStoryCardBlocKProps['storycard']['numberOfArticles'],
      title: title,
      url: url,
      displayDate: displayDate,
      textSize: textSize,
      cardHeight: cardHeight,
      heading: heading ? <BlockRenderer block={heading} /> : undefined,
      hideChevronLink: renderLink,
    }
  },
})

export default ContentfulStoryCardBlock
